import * as React from 'react';
import Layout from '../components/layout';
import Form from '../components/common/form';
import SEO from '../components/seo';

const text = {
  title: 'Contact Us',
  description: 'Use the form below to get in touch with the LogRocket team.',
  buttonValue: 'Send a Message'
}

const IndexPage = () => (
  <Layout>
    <SEO title='LogRocket - Contact Us' description={['LogRocket helps you understand problems affecting your users, so that you can get back to building great software.']} />
    <Form text={text} isOnprem={false} isMessage={true} />
  </Layout>
);

export default IndexPage;
